@import '../../../../../scss/theme-bootstrap';

.product-row {
  display: flex;
  border-bottom: 1px solid $color-cream-stroke;
  padding-top: 24px;
  padding: 24px 0 0;
  &:first-child {
    padding: 0;
  }
  &:last-child {
    border: none;
    .product-row__info-remove {
      padding: 24px 0 0;
    }
  }
  &__image-collection {
    background: url(/media/export/cms/products/100x100/jo_build-a-gift_100x100_72dpi.png) no-repeat 0 0 transparent;
    width: 85px;
    height: 85px;
    display: inline-block;
    background-position: 50% 50%;
    margin-top: -25px;
  }
  &__image-palette {
    background: url(/media/export/cms/products/100x100/jo_sku_L7XL01_100x100_0.png) no-repeat 0 0 transparent;
    width: 85px;
    height: 85px;
    display: inline-block;
    background-position: 50% 100%;
    margin-top: -25px;
    background-size: 145px 145px;
  }
  &__cart-img {
    width: 85px;
    height: 85px;
    display: inline-block;
    img {
      width: inherit;
      height: inherit;
      min-width: 85px;
    }
  }
  &__info {
    padding-#{$ldirection}: 32px;
    display: inline-block;
    width: 220px;
    &-remove {
      float: $rdirection;
      padding: 24px 0;
    }
    &-details-size,
    &-details-qty {
      display: inline-block;
    }
    a {
      &.product-row__info-remove {
        text-decoration: underline;
        line-height: get-line-height(12px, 12px);
      }
    }
  }
}
